import { FC } from 'react';
import { AppContext, DisplayModeType, FooterT } from '@brainysoft/lk-components';
import { FooterView } from '../../../components/Footer/FooterView';
import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import Logo from '../../../components/Logo';
import { Col, Row } from 'antd';
import { NoWrap } from '../../../utils/nowrap';

const footer: FooterT = {
  contacts: [
    [
      {
        label: 'Телефон:',
        text: '8 800 600 53 84',
      },
      {
        label: 'Электронная почта:',
        text: 'info@mrcash.ru',
      },
    ],
    [
      {
        label: 'Адрес',
        text: 'Россия, 630007, Новосибирская область, г. Новосибирск, ул. Коммунистическая, д. 45, офис В329.',
      },
      {
        label: 'График работы:',
        text: 'Понедельник-пятница 09:00-18:00, суббота-воскресенье – выходные дни (выдача займов производится круглосуточно без выходных).',
      },
    ],
  ],
};

export const Footer: React.FC = () => {
  const classes = useThemeStyles('Footer');

  const { state } = AppContext.useContainer();
  const mode = state.displayMode;

  const menu = footer?.menu ?? [];
  const disclaimer = footer?.disclaimer ?? [];
  const copyright = footer?.copyright ?? [];
  const contacts = footer?.contacts ?? [];
  const menuColumnNumber = menu.length ? 24 / menu.length : 24;
  const contactsColumnNumber = contacts.length ? 24 / contacts.length : 24;

  return (
    <footer className={classes.footerWrapper}>
      <div className={classes.footerContainer}>
        <div className={classes.leftSide}>
          <Logo type='footer' />
          {mode !== DisplayModeType.MOBILE && (
            <div className={classes.copyright}>
              <div>{copyright}</div>
              <div className={classes.brainysoft}>
                Powered by{' '}
                <a href={'https://www.brainysoft.ru'} target={'_blank'} rel='noopener noreferrer'>
                  Brainysoft
                </a>
              </div>
            </div>
          )}
        </div>
        <div className={classes.rightSide}>
          <Row>
            {menu.map((column, index) => {
              const list = Array.isArray(column) ? column : [];
              return (
                <Col xs={24} md={menuColumnNumber} key={index}>
                  <ul className={classes.footerMenu}>
                    {list.map((item, index) => {
                      return (
                        <li className={classes.footerMenuItem} key={index}>
                          <a href={item.url} target={item.target}>
                            {item.text}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              );
            })}
          </Row>
          <Row>
            {contacts.map((column, index) => {
              const list = Array.isArray(column) ? column : [];
              return (
                <Col xs={24} md={contactsColumnNumber} key={index}>
                  <ul className={classes.footerMenu}>
                    {list.map((item, index) => {
                      const text = Array.isArray(item.text) ? item.text : [item.text];
                      return (
                        <li className={classes.footerMenuItem} key={index}>
                          <div className={classes.footerContactLabel}>{item.label}</div>
                          <div className={classes.footerContactContent}>
                            {!!item?.url && (
                              <a href={item.url} target={item.target}>
                                {text.map((txt, index) => {
                                  return <div key={index}>{txt}</div>;
                                })}
                              </a>
                            )}
                            {!item?.url &&
                              text.map((txt, index) => {
                                return <div key={index}>{txt}</div>;
                              })}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              );
            })}
          </Row>

          <div className={classes.footerDisclaimer}>
            <div className={classes.footerDisclaimerItem}>
              Общество с ограниченной ответственностью Микрокредитная компания «Мистер Кэш» (ООО МКК «Мистер Кэш»)
            </div>
            <div className={classes.footerDisclaimerItem}>ОГРН 1235400016059 / ИНН 5406830971</div>
            <div className={classes.footerDisclaimerItem}>
              Свидетельство о внесении ООО МКК «Мистер Кэш» в государственный реестр микрофинансовых организаций №
              2304150009967 от 10.07.2023г.
            </div>
            <div className={classes.footerDisclaimerItem}>
              Членство в Саморегулируемой организации «Микрофинансирование и Развитие» с __.___.2023г. Адрес СРО:
              107078, Москва, Орликов переулок, д. 5, стр. 1, этаж 2, пом. 11,{' '}
              <NoWrap>
                сайт{' '}
                <a href={'https://npmir.ru/'} target={'_blank'} rel={'noreferrer noopener'}>
                  https://npmir.ru/
                </a>
              </NoWrap>
            </div>

            <div className={classes.footerDisclaimerItem}>
              Официальный сайт Банка России{' '}
              <a href={'https://www.cbr.ru/'} target={'_blank'} rel={'noreferrer noopener'}>
                https://www.cbr.ru/
              </a>
            </div>
            <div className={classes.footerDisclaimerItem}>
              Реестр микрофинансовых организаций{' '}
              <a href={'https://cbr.ru/microfinance/registry/'} target={'_blank'} rel={'noreferrer noopener'}>
                https://cbr.ru/microfinance/registry/
              </a>
            </div>
            <div className={classes.footerDisclaimerItem}>
              Интернет-приемная Банка России{' '}
              <a href={'https://cbr.ru/reception/'} target={'_blank'} rel={'noreferrer noopener'}>
                https://cbr.ru/reception/
              </a>
            </div>
            <div className={classes.footerDisclaimerItem}>
              Контакты Банка России: 8 800 300-30-00 (бесплатно по России), 8 499 300-30-00 (тарифы Вашего оператора),
              300 (бесплатно с мобильных телефонов).
            </div>
            <div className={classes.footerDisclaimerItem}>
              Потребитель финансовых услуг вправе направить обращение финансовому уполномоченному на основании
              Федерального закона от 04.06.2018 N 123-ФЗ «Об уполномоченном по правам потребителей финансовых услуг»:
              адрес 119017, г. Москва, Старомонетный пер., дом 3 (получатель АНО «СОДФУ»); телефон 8 (800) 200-00-10
              (бесплатно по России);{' '}
              <NoWrap>
                сайт{' '}
                <a href={'https://finombudsman.ru/'} target={'_blank'} rel={'noreferrer noopener'}>
                  https://finombudsman.ru/
                </a>
              </NoWrap>
            </div>
          </div>

          {mode === DisplayModeType.MOBILE && (
            <div className={classes.copyright}>
              <div>{copyright}</div>
              <div className={classes.brainysoft}>
                Powered by{' '}
                <a href={'https://www.brainysoft.ru'} target={'_blank'} rel='noopener noreferrer'>
                  Brainysoft
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};
