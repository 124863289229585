import { useInterval } from './useInterval';
import { cacheService as Storage, otpService, creditService } from '@brainysoft/lk-components';
import moment from 'moment';
import { SMS_CHECK_INTERVAL } from '../const';
import { SMS_SEND_DELAY } from '../config';
import { OtpChallengeType } from '@brainysoft/lk-components';
import { throttle } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useInvisibleCaptcha } from './useInvisibleCaptcha';

type UseChallenge = {
  getChallenge: (payload?: object) => void;
  isDisabled: boolean;
  isSending: boolean;
  isSent: boolean;
  timeout?: number;
  channel?: string;
  initHandshake: () => void;
  isHandshakeLoading: boolean;
  authId?: number;
};

export const useChallenge: (code: OtpChallengeType, mobilePhone?: string) => UseChallenge = (code, mobilePhone) => {
  const [isHandshakeLoading, setIsHandshakeLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [currentChannel, setCurrentChannel] = useState<string | undefined>();
  const [nextChannel, setNextChannel] = useState<string | undefined>();
  const [authId, setAuthId] = useState<number | undefined>();

  const [smsCheckInterval, setSmsCheckInterval] = useState<number | null>(SMS_CHECK_INTERVAL);
  const [timeout, setTimeout] = useState<number | undefined>();
  const refPhone = useRef('');

  const handshake = async () => {
    let res;
    setIsHandshakeLoading(true);

    try {
      if (mobilePhone) res = await interceptor(otpService.handshake.bind(otpService))(code, mobilePhone);
      if (res && res.channel) {
        setCurrentChannel(res.channel);
        setNextChannel(res.channel);
        await Storage.setItem(`challenge:${code}:channel`, res.channel);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsHandshakeLoading(false);
    }
  };

  const { interceptor } = useInvisibleCaptcha();

  useEffect(() => {
    handshake();
    if (mobilePhone) refPhone.current = mobilePhone;
  }, [code, mobilePhone]);

  useInterval(() => {
    let cancel = false;
    const checkCooldown = async () => {
      const cooldown = (await Storage.getItem(`challenge:${code}:cooldown`)) as string;
      if (moment().isSameOrBefore(moment(Number(cooldown)))) {
        if (!cancel) {
          setTimeout(moment().diff(moment(Number(cooldown))));
        }
      } else {
        if (!cancel) {
          // закончился таймаут - доступный канал из предыдущего челленджа становится текущим
          await Storage.setItem(`challenge:${code}:channel`, nextChannel);
          setCurrentChannel(nextChannel);
          setSmsCheckInterval(null);
          setTimeout(undefined);
        }
      }
    };
    checkCooldown();
    return () => {
      setSmsCheckInterval(null);
      cancel = true;
    };
  }, smsCheckInterval);

  const getChallenge = useCallback(
    throttle((mobilePhone) => _getChallenge(mobilePhone), 2000, { trailing: false }),
    []
  );

  const _getChallenge: (payload?: any) => void = async (payload) => {
    setIsSending(true);
    let smsDelay = moment().add(SMS_SEND_DELAY, 'ms');

    try {
      let res;
      switch (code) {
        case OtpChallengeType.ENTRANCE:
        case OtpChallengeType.AUTH:
        case OtpChallengeType.REGISTRATION:
        case OtpChallengeType.DOCUMENTS_SIGN:
        case OtpChallengeType.DOCUMENTS_SIGN_UPDATE:
        case OtpChallengeType.SIMILAR_DATA_AUTH:
          if (!refPhone.current) throw new Error('Undefined Phone');
          res = await interceptor(otpService.getChallenge.bind(otpService))(code, {
            mobilePhone: refPhone.current,
            ...payload,
          });
          break;
        case OtpChallengeType.PROLONGATION:
        case OtpChallengeType.LOAN_APPLICATION:
        case OtpChallengeType.EARLY_REPAYMENT:
          res = await interceptor(otpService.getChallengeWithConsumer.bind(otpService))(code, payload);
          break;
        case OtpChallengeType.OFERTA:
          res = await interceptor(creditService.signOfferChallenge.bind(otpService))(payload?.uuid as string);
          break;
        default:
          console.error('unknown challenge type', code);
      }

      if (res?.challenge?.id) {
        setAuthId(res?.challenge?.id);
        setIsSent(true);
        setIsSending(false);
        const delay = parseInt(res?.available?.availableIn);
        smsDelay = moment().add(delay ? delay * 1000 : SMS_SEND_DELAY, 'ms');
        setNextChannel(res?.available?.channel);
      } else {
        console.log('error no challengeId', res);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {
      console.log('error', error);
    }

    await Storage.setItem(`challenge:${code}:cooldown`, smsDelay.valueOf());
    setSmsCheckInterval(SMS_CHECK_INTERVAL);
    setIsSending(false);
  };

  return {
    getChallenge: getChallenge,
    isDisabled: smsCheckInterval !== null || !currentChannel || isSending,
    isSending,
    isSent,
    timeout,
    channel: currentChannel,
    initHandshake: handshake,
    isHandshakeLoading,
    authId,
  };
};

export const parseTimeout = (timeout) => {
  let minutes, seconds;
  if (!isNaN(Number(timeout))) {
    minutes = !isNaN(Number(timeout)) ? Math.floor(Math.abs(Number(timeout)) / 60000) : undefined;
    seconds = !isNaN(Number(timeout)) ? Math.floor(Math.abs(Number(timeout)) / 1000) - minutes * 60 : undefined;
  }

  return {
    minutes: minutes,
    seconds: seconds,
  };
};
