import React from 'react';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import { FormItem } from '../FormItem';
import { Field } from 'formik';
import { InputNumber, Slider as AntdSlider } from 'antd';
// eslint-disable-next-line no-unused-vars
import { SliderBaseProps } from 'antd/lib/slider';

interface ISliderProps extends SliderBaseProps {
  className?: string;
  name: string;
  label?: string;
  onChange?: any;
  onBlur?: any;
  hidden?: boolean;
  defaultValue?: number;
  min: number;
  max: number;
  step?: number;
  unit?: string;
  unitWeight?: number;
  required?: boolean;
  mask?: any;
  pattern?: any;
  formated?: boolean;
}

// eslint-disable-next-line react/display-name
export const Slider = (props: ISliderProps) => {
  const classes = useThemeStyles('Form/Slider');

  const [sliderDisplayValue, setSliderDisplayValue] = React.useState<number>(0);

  const unitWeight = props.unitWeight ? props.unitWeight : 1;
  const min = Math.floor(props.min / unitWeight);
  const max = Math.floor(props.max / unitWeight);
  const step = props.step ? props.step : 1;
  const formated = !!props.formated;

  const validateRequired = (value) => {
    return !value ? 'required' : undefined;
  };

  const formattedValue = (value: number) => {
    if (!formated) return value;
    const rgx = /\B(?=(\d{3})+(?!\d))/g;
    const valueStr = value.toString();
    if (rgx.test(valueStr)) {
      return valueStr.replace(rgx, ' ');
    }
    return value;
  };

  return (
    <div style={{ width: '100%' }}>
      <Field name={props.name} validate={props.required ? validateRequired : null}>
        {({ form, field, meta }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          React.useEffect(() => {
            let fieldValue = field.value ? Math.round(field.value / unitWeight) : min;
            fieldValue = fieldValue < min ? min : fieldValue;
            fieldValue = fieldValue > max ? max : fieldValue;
            setSliderDisplayValue(fieldValue);

            form.setFieldValue(field.name, fieldValue * unitWeight);
          }, [unitWeight, min, max]);

          return (
            <React.Fragment>
              <div className={`${classes.numberWrapper}`}>
                <label className={classes.numberLabel}>{props.label}</label>
                <div className={classes.numberValueWrapper}>
                  <InputNumber
                    min={min}
                    max={max}
                    step={step}
                    value={formattedValue(sliderDisplayValue)}
                    className={`${classes.numberInput} ${props.className}`}
                    onChange={(value: number) => {
                      //здесь возвращается number (отображается 500 000 -> возвращается 500000)
                      value = value === undefined ? min : value;
                      setSliderDisplayValue(Number(value));
                    }}
                    onBlur={(e) => {
                      //здесь в e.target.value возвращается string (отображается 500 000 -> возвращается 500 000)
                      //поэтому берем исходное значение sliderDisplayValue
                      let value = Math.round(sliderDisplayValue / step) * step;
                      value = value === undefined ? min : value;
                      value = value < min ? min : value;
                      value = value > max ? max : value;
                      setSliderDisplayValue(Number(value));
                      form.setFieldValue(field.name, Number(value) * unitWeight);
                      form.setFieldTouched(field.name, true);
                    }}
                  />
                </div>
              </div>
              <FormItem {...props} meta={meta} label={''}>
                <AntdSlider
                  min={min}
                  max={max}
                  step={step}
                  tooltipVisible={false}
                  range={false}
                  value={sliderDisplayValue}
                  className={`${classes.slider}  ${props.className}`}
                  onChange={(value) => {
                    setSliderDisplayValue(Number(value));
                    form.setFieldValue(field.name, Number(value) * unitWeight);
                    form.setFieldTouched(field.name, true);
                  }}
                />
              </FormItem>
            </React.Fragment>
          );
        }}
      </Field>
    </div>
  );
};
