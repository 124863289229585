import * as React from 'react';
import { CreditT } from '@brainysoft/lk-components';

interface IActiveCreditInfo {
  credit: CreditT;
}

export const ActiveCreditInfo: React.FC<IActiveCreditInfo> = (props) => {
  return <></>;
};
